.updates {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 60px 0 40px 0;

	@media screen and (max-width: 500px) {
		padding: 60px 0 0 0;
		min-height: auto;
	}

	.update-list {
		width: 100%;
		display: flex;
		align-items: center;
		overflow-x: auto;
		scrollbar-width: none;
		-webkit-overflow-scrolling: touch;

		> :first-child {
			margin-left: 60px;

			@media screen and (max-width: 500px) {
				margin-left: 0;
			}
		}

		&::-webkit-scrollbar {
		  display: none;
		}
	}
}

.updates-mobile {
	position: relative;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	height: calc(100vh - 80px);
}