body {
	font-family: 'Amiri', 'Noto Sans TC', serif;
	font-weight: 300;
	color: black;
  background-color: white;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lexend Deca', sans-serif;
  letter-spacing: 3.2px;
  font-weight: 400;
	color: black;
  text-transform: uppercase;
}

h1 {
  font-size: 34px;
  letter-spacing: 0.2em;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 20px;
  }
}

a {
  	font-family: 'Lexend Deca', sans-serif;
    text-decoration: none;
    color: black;
}

* {
  box-sizing: border-box;
}