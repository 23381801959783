.carousel {
	position: relative;
	height: 100%;
	width: 100%;
	background: black;
	white-space: nowrap;
	overflow: hidden;

	.slider-wrapper {
		height: 100%;
		width: 100%;
		transition: all 500ms cubic-bezier(0.7, 0, 0.3, 1);
		-webkit-transition: all 500ms cubic-bezier(0.7, 0, 0.3, 1);
	}

	.indicators {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;

		@media screen and (max-width: 500px) {
			bottom: 10px;
		}

		.indicator {
			content: '';
			display: block;
			width: 15px;
			height: 15px;
			border: solid 2px white;
			border-radius: 50%;
			margin: 0 10px;
			cursor: pointer;
			transition: all 300ms ease-in-out;
			-webkit-transition: all 300ms ease-in-out;

			@media screen and (max-width: 500px) {
				width: 10px;
				height: 10px;
			}

			&.current {
				background-color: white;
			}
		}
	}
}