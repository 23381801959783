.arrow {
	width: auto;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	cursor: pointer;
	position: absolute;
	bottom: 50%;
	transform: translateY(50%);
	transition: all 500ms ease-in-out;
	-webkit-transition: all 500ms ease-in-out;

	@media screen and (max-width: 500px) {
		height: 50px;
	}

	@media screen and (max-height: 660px) {
		bottom: 60%;
	}

	&.left-button {
		left: 0;
	}

	&.right-button {
		right: 0;
	}

	&.hide-back,
	&.hide-next {
		pointer-events: none;
		opacity: 0;
	}

	&.hide-back {
		transform: translate(-100px, 50%);
		transform: translate3d(-100px, 50%, 0);		
		-webkit-transform: translate(-100px, 50%);
		-webkit-transform: translate3d(-100px, 50%, 0);
	}

	&.hide-next {
		transform: translate(100px, 50%);
		transform: translate3d(100px, 50%, 0);		
		-webkit-transform: translate(100px, 50%);
		-webkit-transform: translate3d(100px, 50%, 0);
	}

	&.show-back,
	&.show-next {
		opacity: 1;
		-webkit-filter: opacity(1);
		transform: translate(0, 50%);
		transform: translate3d(0, 50%, 0);		
		-webkit-transform: translate(0, 50%);
		-webkit-transform: translate3d(0, 50%, 0);
	}

	&.upper {
		transform: none;
		bottom: 63%;
	}

	.button {
		width: 100%;
		height: 100%;
		padding: 10px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: black;

		@media screen and (max-width: 768px) {
			padding: 5px 15px;
		}

		.left,
		.right {
			width: 30px; 
			height: 30px;
			transition: all 300ms ease-in-out;
			-webkit-transition: all 300ms ease-in-out;
			box-shadow: -3px 3px 0 white;

			@media screen and (max-width: 1024px) {
				width: 22px;
				height: 22px;
			}

			@media screen and (max-width: 768px) {
				width: 15px;
				height: 15px;
			}
		}

		.left {
			transform: translateX(10px) rotate(45deg);
		}

		.right {
			transform: translateX(-10px) rotate(225deg);
		}
	}
	
	.more {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		text-transform: uppercase;
		letter-spacing: 6px;
		padding: 0 10px 0 25px;
		color: white;
		font-family: 'Lexend Deca', sans-serif;
		line-height: 24px;
		font-size: 14px;
		background: black;
		transform-origin: right;
		-webkit-transform-origin: right;
		transition: all 300ms ease-in-out;
		-webkit-transition: all 300ms ease-in-out;

		@media screen and (max-width: 1024px) {
			font-size: 12px;
			padding: 0 20px;
		}

		@media screen and (max-width: 768px) {
			padding: 0 0 0 20px;
		}

		@media screen and (max-width: 500px) {
			letter-spacing: 3px;
			line-height: 16px;
		}

		&.show-more {
			transform: scale(1);
			-webkit-transform: scale(1);
		}

		&.hide-more {
			transform: scale(0, 1);
			-webkit-transform: scale(0, 1);
		}
	}
}