.updates-item {
	width: 380px;
	min-width: 350px;
	min-height: 530px;
	height: 75vh;
	margin-right: 20px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: white;

	@media screen and (max-width: 500px) {
		width: 100%;
		min-width: unset;
		align-items: center;
		margin-right: 0;
		min-height: calc(100vh - 80px);
		display: inline-flex;
		vertical-align: top;
		white-space: normal;
	}

	.image {
		width: 100%;
		height: 50%;
		background-position: center;
		background-size: cover;
	}

	.content {
		margin: 25px 0 auto 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media screen and (max-width: 500px) {
			margin: auto 0;
			align-items: center;
			text-align: center;
			width: 285px;
			justify-content: center;
		}

		.date {
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 4px;
			font-family: 'Lexend Deca', sans-serif;
		}

		.italic {
			font-style: italic;
			text-transform: capitalize;
			letter-spacing: 1px;
		}

		h3 {
			margin: 0 0 10px 0;

			@media screen and (max-width: 500px) {
				font-size: 16px;
			}
		}

		p {
			margin: 0;
			max-height: 108px;
			font-size: 18px;
			line-height: 36px;
			width: 100%;
			text-overflow: ellipsis;
			position: relative;
			overflow: hidden;
			text-align: left;
			margin-right: -1.4em;
			padding-right: 1.4em;

			&:before {
				background: white;
				content: ' . . .';
				position: absolute;
				bottom: 0;
				right: 0;
			}

			&:after {
				content: '';
				position: absolute;
				right: 0;
				background: white;
				width: 1.2em;
				height: 1.5em;
				margin-top: 0.2em;
			}

			@media screen and (max-width: 500px) {
				font-size: 16px;
				line-height: 30px;
				max-height: 90px;
				text-align: center;
			}
		}

		.button {
			margin-top: 14px;

			@media screen and (max-width: 500px) {
				margin-top: 20px;
			}
		}
	}
}