.drop-menu {
	position: fixed;
	top: 0;
	left: 20px;
	height: 100%;
	width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	z-index: 10;
	transition: all 500ms ease-in-out;
	-webkit-transition: all 500ms ease-in-out;
	padding: 60px 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
	  display: none;
	}

	@media screen and (max-width: 500px) {
      max-width: 300px;
    }

	&.show {
		opacity: 0.9;
		-webkit-filter: opacity(0.9);
		transform: translate(0,0);
		-webkit-transform: translate(0,0);
	}

	&.hide {
		opacity: 0;
		-webkit-filter: opacity(0);
		transform: translate(0,-100%);
		-webkit-transform: translate(0,-100%);
	}

	.drop-menu-content {
		position: absolute;
		display: flex;
		flex-direction: column;
		height: auto;

		.icon-set {
			width: auto;
			height: auto;
			display: flex;
			flex-direction: row;
			align-items: center;

			&:hover .icon {
				fill: gray;
			}

			.icon {
				width: 24px;
				height: 24px;
				margin-right: 20px;
				transition: all 200ms ease-in-out;
				fill: white;
				cursor: pointer;

				&:hover {
					fill: white;
				}
			}
		}
	}
}