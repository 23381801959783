.contact {
	height: 100vh;
	padding: 80px 60px 20px 60px;

	@media screen and (max-width: 1024px) {
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: unset;
	}

	@media screen and (max-width: 500px) {
		padding: 0 40px;
	}

	.heading {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media screen and (max-width: 1024px) {
			padding: 20px;
			align-items: center;
		}

		@media screen and (max-width: 768px) {
			max-width: 350px;
			margin: 0 auto;

			.row :first-child {
				margin: 8px 5px auto 0;
			}
		}

		@media screen and (max-width: 500px) {
			padding: 40px 0 20px 0;
		}
	}

	.bottom {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 1024px) {
			margin-top: 0;
		}

		.details {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@media screen and (max-width: 1024px) {
				width: 100%;
				align-items: center;
			}

			@media screen and (max-width: 500px) {
				padding-bottom: 40px;
			}
		}

		.map {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	

	.row, .address-row {
		display: flex;
		align-items: center;

		&.address-row {
			gap: 26px;
			flex-wrap: wrap;

			@media screen and (max-width: 1024px) {
				margin-bottom: 20px;
				gap: 12px;
			}

			@media screen and (max-width: 500px) {
				gap: 12px;
			}
		}

		@media screen and (max-width: 1024px) {
			justify-content: center;
			text-align: center;
		}

		* {
			font-family: 'Amiri', serif;
			font-size: 18px;
			line-height: 53px;

			@media screen and (max-width: 768px) {
				line-height: 38px;
			}
		}

		svg {
			width: 18px;
			height: 18px;
			margin-right: 20px;

			@media screen and (max-width: 768px) {
				margin-right: 8px;
			}
		}

		.pin {
			width: 18px;
			height: 18px;
		}

		.inner-row {
			display: flex;
			align-items: center;
		}
	}
}