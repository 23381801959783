.menu {
  display: flex;
  align-items: center;
  outline: none;
  height: 50%;
  width: auto;
  z-index: 11;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;

  &.close {
    position: fixed;
    transform: translate3d(320px,0,0);
    -webkit-transform: translate3d(320px,0,0);

    @media screen and (max-width: 500px) {
      transform: translate3d(240px,0,0);
      -webkit-transform: translate3d(240px,0,0);
    }

    .menu-lines {

      div {
        transform: scale(0);
        -webkit-transform: scale(0);
      }

      &:before {
        background: white;
        transform: translate3d(0,9px,0) rotate(135deg);
        -webkit-transform: translate3d(0,9px,0) rotate(135deg);
      }

      &:after {
        transform: translate3d(0,-9px,0) rotate(-135deg);
        -webkit-transform: translate3d(0,-9px,0) rotate(-135deg);
      }
    }
  }

  .menu-button {
      cursor: pointer;
      width: 24px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;

      .menu-lines {
        position: relative;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before,
        &:after,
        div {
          position: absolute;
          content: "";
          display: block;
          background: black;
          width: 100%;
          height: 2px;
        }
        
        &:before {
          left: 0;
          top: -10px;
        }

        &:after {
          left: 0;
          bottom: -10px;
        }

        &.inverted {
          background: white;

          &:before,
          &:after,
          div {
            background: white;
          }
        }
      }
  }
}