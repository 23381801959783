.home {
	height: 100vh;
	min-height: 700px;
	width: 100vw;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	@media screen and (max-width: 500px) {
      min-height: unset;
    }

	.menu-button-header {
		position: absolute;
		top: 0;
		left: 0;
		height: 80px;
		width: 100%;
		padding: 10px 40px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media screen and (max-width: 500px) {
	      padding: 10px 20px;
	    }
	}

	.center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.logo-container {
			margin-bottom: 140px;
			display: flex;
			justify-content: center;
			overflow: hidden;

			@media screen and (max-width: 768px) {
			    margin-bottom: 60px;
			}

			svg {
				width: 750px;
				height: 250px;
				max-width: 70%;

			}
		}
	}

	.scroll-message {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		color: white;
		font-size: 12px;
		font-family: 'Lexend Deca', sans-serif;
		letter-spacing: 2.8px;

		@media screen and (max-width: 768px) {
		    font-size: 10px;
		}
	}
}