.alert {
	position: fixed;
	background: #b2e4d5;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 28px;
  border-radius: 4px;
  z-index: 5;
  overflow: hidden;
  pointer-events: none;
  transition: all 500ms ease-in-out;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);

    &.fade-in {
      opacity: 1;
    }

    &.fade-out {
      opacity: 0;
    }

    .message {
      font-family: 'Lexend Deca', sans-serif;
  		text-transform: uppercase;
  		font-weight: 500;
      text-align: center;
    }
}