.our-story {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 60px;

	@media screen and (max-width: 1024px) {
		padding: 0;
		margin: 0 0 20px 0;
		height: auto;
	}

	.text {
		width: 100%;
		text-align: center;
		max-width: 1000px;

		@media screen and (max-width: 1024px) {
			padding: 20px 40px;
			max-width: 700px;
		}

		@media screen and (max-width: 500px) {
			padding: 20px;
		}

		p {
			font-size: 18px;
			line-height: 45px;

			@media screen and (max-width: 1024px) {
				line-height: 45px;
			}

			@media screen and (max-width: 768px) {
				line-height: 35px;
			}

			@media screen and (max-width: 500px) {
				font-size: 14px;
				line-height: 30px;
			}
		}
	}
}