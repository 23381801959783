.parallax-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	.info-container {
		width: 100%;
		height: 100%;
	}
}