.options {
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		.option {
			color: gray;
		}
	}

	&.vertical {
		flex-direction: column;
		align-items: flex-start;
		margin: 20px 0;

		.option {
			margin: 5px 0;
		}
	}

	.admin {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: 2px solid white;
		padding: 10px 0;
		margin-bottom: 10px;
	}

	.option {
		margin: 0 10px;
		font-family: 'Amiri', serif;
		font-size: 18px;
		letter-spacing: 4.32px;
		line-height: 60px;
		color: white;
		text-transform: lowercase;
		transition: all 300ms ease-in-out;

		&:hover {
			color: white;
		}

		&.show {
			opacity: 1;
			animation: slide-in 500ms ease-in-out;
		}

		&.hide {
			opacity: 0;
			animation: slide-out 500ms ease-in-out;
		}

		@keyframes slide-in {
          0% {
          	opacity: 0;
            transform: translateX(-10px);
          }
          100% {
          	opacity: 1;
            transform: none;
          }
        }

        @keyframes slide-out {
          0% {
          	opacity: 1;
            transform: none;
          }
          100% {
          	opacity: 0;
            transform: translateX(-10px);
          }
        }
	}
}