.header {
  position: fixed;
  height: 80px;
  width: 100%;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  overflow-x: hidden;
  z-index: 5;
  transition: all 500ms ease-in-out;

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }

  &.inverted {
    background-color: transparent;

    svg {
      fill: white;
    }
  }

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }

  .logo-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 180px;
    max-width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  }

  .book-button {
    margin-left: auto;
  }
}
